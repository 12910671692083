<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <ion-page><cyan-page-wrapper
    backRoute="/twoFactorStart"
    title="Verificar código"
    :isForm="true"
  >
    <cyan-form>
      <ion-item lines="none">
        <ion-label position="stacked">Cédula</ion-label>
        <div class="inputWrapper">
          <ion-input
            type="tel"
            v-model="storedDui"
            placeholder="12345678-9"
            class="__twoFactorConfirm-DUI"
          ></ion-input>
          <div class="validationFailed">
            <ion-icon :icon="warningOutline"></ion-icon>
          </div>
        </div>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="stacked">Código SMS</ion-label>
        <div class="inputWrapper">
          <ion-input
            v-model="smscode"
            autofocus="true"
            placeholder="6 caracteres"
            class="__twoFactorConfirm-code"
          ></ion-input>
          <div class="validationFailed">
            <ion-icon :icon="warningOutline"></ion-icon>
          </div>
        </div>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="stacked">Contraseña</ion-label>
        <div class="inputWrapper">
          <ion-input
            v-model="password"
            placeholder="Al menos 5 caracteres"
            clearOnEdit="false"
            :type="passwordVisible ? 'text' : 'password'"
            class="__twoFactorConfirm-password"
          ></ion-input>
          <div
            class="eyeIcon"
            v-if="password.length"
            @click="toggleViewPassword"
          >
            <ion-icon
              :icon="passwordVisible ? eyeOffOutline : eyeOutline"
            ></ion-icon>
          </div>
          <div class="validationFailed iconWhiteBack">
            <ion-icon :icon="warningOutline"></ion-icon>
          </div>
        </div>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="stacked">Confirme contraseña</ion-label>
        <div class="inputWrapper">
          <ion-input
            v-model="password2"
            clearOnEdit="false"
            type="password"
            class="__twoFactorConfirm-password2"
          ></ion-input>
          <div class="validationFailed">
            <ion-icon :icon="warningOutline"></ion-icon>
          </div>
        </div>
      </ion-item>
      <ion-item lines="none">
        <ion-label>Recordar mis datos</ion-label>
        <ion-checkbox v-model="remember"></ion-checkbox>
      </ion-item>

      <ion-button
        class="__twoFactorConfirm-next"
        size="large" expand="block"
        @click="checkCode"
        tabindex="0"
      >
        <span class="button-right-wrapper">Guardar y acceder</span>
        <ion-icon slot="end" :icon="arrowForwardCircleOutline"></ion-icon>
      </ion-button>

      <ion-button
        class="dimButton mobileShowBlock" expand="block"
        @click="$router.replace('/twoFactorStart')"
      >
        <span class="button-right-wrapper">Volver</span>
        <ion-icon slot="end" :icon="arrowBackCircleOutline"></ion-icon>
      </ion-button>
    </cyan-form>
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  router,
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";
import CyanForm from "@/components/CyanForm.vue";
import {
  arrowForwardCircleOutline,
  arrowBackCircleOutline,
  warningOutline,
  eyeOutline,
  eyeOffOutline,
} from "ionicons/icons";

import ValidatorSet, {
  validateDUI,
  validateRegexp,
} from "@/modules/cyanValidator";

import {
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonPage,
  IonInput,
  IonCheckbox,
} from "@ionic/vue";

export default defineComponent({
  name: "TwoFactorConfirm",
  components: {
    CyanPageWrapper,
    CyanForm,
    IonPage,
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonInput,
    IonCheckbox,
  },
  computed: {
    storedDui: {
      get() {
        return store.getters.smsRequestUser;
      },
      set(value) {
        store.commit("requestedSMSCode", value);
      },
    },
  },
  data() {
    return {
      smscode: "",
      password: "",
      password2: "",
      remember: false,
      validator: null as any,
      passwordVisible: false,
    };
  },
  mounted() {
    const validarPassword2 = function (_: any, v: string) {
      const password = (document.querySelector(
        ".__twoFactorConfirm-password"
      ) as any).value;
      const isOK = v === password;
      return {
        ok: isOK,
        formatted: v,
        isFull: false,
        reason: isOK ? "" : "Las contraseñas no coinciden.",
      };
    };

    this.validator = new ValidatorSet([
      {
        validator: validateDUI,
        element: ".__twoFactorConfirm-DUI",
        nextElement: ".__twoFactorConfirm-code",
      },
      {
        validator: validateRegexp,
        element: ".__twoFactorConfirm-code",
        nextElement: ".__twoFactorConfirm-password",
        validatorParams: {
          regexp: "^......$",
          full: true,
          noMatch: "El código está compuesto de 6 caracteres.",
        },
      },
      {
        validator: validateRegexp,
        element: ".__twoFactorConfirm-password",
        nextElement: ".__twoFactorConfirm-password2",
        validatorParams: {
          regexp: ".....",
          noMatch: "La contraseña debe tener al menos 5 caracteres.",
        },
      },
      {
        validator: validarPassword2,
        element: ".__twoFactorConfirm-password2",
        nextElement: ".__twoFactorConfirm-next",
      },
    ]);
    document
      .querySelector(".__login-next")
      ?.addEventListener("keyup", (ev: any) => {
        if (ev.key == "Enter" || ev.key == " ") this.checkCode();
      });
  },

  setup() {
    return {
      arrowForwardCircleOutline,
      arrowBackCircleOutline,
      warningOutline,
      eyeOutline,
      eyeOffOutline,
    };
  },
  methods: {
    toggleViewPassword() {
      this.passwordVisible = !this.passwordVisible;
    },

    async checkCode() {
      if (this.validator) {
        if (!(await this.validator.checkWithAlert())) return;
      }

      const d = await cyanRequest("setPassword", {
        isModal: true,
        isPost: true,
        needsPreflight: !this.$data.remember,
        params: {
          name: this.storedDui,
          password: this.$data.password,
          password2: this.$data.password2,
          code: this.$data.smscode,
          requestToken: this.$data.remember ? "1" : "",
        },
      });

      if (!d.ok) return;

      store.commit("userLogin", d);
      router.replace("/home");
    },
  },
});
</script>

<style scoped>
.iconWhiteBack {

  height: 32px;
  background: white;
}
</style>