<template>
  <div class="cyanFormOuter">
    <div class="cyanFormDummy"></div>
    <div class="cyanForm">
      <img class="logoform" v-if="logo" src="assets/images/LogoLoginCreo.svg" />
      <ion-label class="header" v-if="header != ''">
        {{ header }}
      </ion-label>
      <ion-label class="subheader" v-if="subtitle != ''">
        {{ subtitle }}
      </ion-label>

      <slot></slot>
    </div>
    <div class="cyanFormDummy"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonLabel } from '@ionic/vue';

export default defineComponent({
  name: "CyanForm",
  components: { IonLabel },
  data() {
    return {};
  },
  props: {
    header: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    logo: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style scoped>
.cyanFormDummy {
  visibility: hidden;
  height: 0px;
}
.cyanFormOuter {
  background: var(--ion-color-primary);
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: auto;
  align-items: flex-start;
  justify-content: space-between;
  top:0;
  bottom:0;
  left:0;
  right:0;
  position:absolute;
}
.cyanForm {
  background: var(--ion-color-primary);
  flex: 0 1 auto;
  max-width: 500px;
  margin: 20px auto;
}

@media (min-width: 768px) and (min-height: 500px) {
  .cyanForm {
    padding: 40px 40px 20px;
    border-radius: 20px;
  }
  .cyanFormOuter {
    background: var(--ion-color-tertiary);
    padding: 20px;
  }
}
</style>